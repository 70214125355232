.Admin-Segment-Wrapper{    
    /* width: 95% !important; */
    margin-left: auto;
    margin-right: auto;    
}
.Admin-Header-Segment{
    /* background-color: #F4911E !important; */
    /* color: #00395A !important; */
    background-color: var(--EquiMainColour) !important;
    color: var(--EquiTextColour) !important;
}
.Admin-Div-Name-Region{
    display: inline-flex !important;
}
.Admin-Div-FirstName-Input{
    width: 350px !important;
}
.Admin-Div-LastName-Input{
    width: 350px !important;
}
.Admin-Div-Username{
    width: 550px !important;
    display: inline-flex;
}
.Admin-Div-Phone1{
    width: 350px !important;
}
.Admin-Div-Phone2{
    width: 350px !important;
}
.Admin-Div-Role{
    width: 350px !important;
}
.Admin-Div-Company{
    width: 350px !important;
}
.Admin-Div-UserIsStaff{
    display: inline-flex !important;
    align-items: center;
}
.Admin-Label-UserIsStaff{
    /* background-color: #F4911E !important; */
    /* color: #00395A !important; */
    background-color: var(--EquiMainColour) !important;
    color: var(--EquiTextColour) !important;
}
.Admin-Checkbox-UserIsStaff{
    width: 25px;
    height: 25px;
}
.Admin-Edit-Hide{
    display: none;
}
.Admin-Modal-CreateResult{
    margin-left: auto !important;
    margin-right: auto !important;
    margin-top: auto !important;
    margin-bottom: auto !important;
}
.Admin-Form-Field-Sm{
    display: inline-flex;
        width: 30% !important;
}
.Admin-Div-Postcodes{
    width: 650px !important;
    display: inline-flex;
}
.Admin-Button-Clear{
    width: 160px !important;
}
.Admin-ButtonGroup-Validate-Fail{
    border: 1px solid red;
    border-radius: 5px;
}
.Admin-Div-Float-Left{
    float: left;
}
.Admin-Div-Float-Right{
    float: right;
}
.Admin-Div-CreateSchedule{
    width: 700px;
    margin-top: -50px !important;
}
.Admin-DivInline{
    display: inline !important;    
}
.Admin-DivInlineBlock {
    display: inline-block !important;
    color: var(--EquiTextColour) !important;
}
.Admin-CreateScheduleLabel{
    margin-top: -15px !important;
    padding-bottom: 10px;
}
.Admin-CreateScheduleButton{
    margin-top: -38px !important;
    z-index: 10;
}
.Admin-DivCalendar{
    width: 230px;
}
.Admin-BG-Orange{
    /* background-color: #F4911E !important; */
    /* color: #00395A !important; */
    background-color: var(--EquiMainColour) !important;
    color: var(--EquiTextColour) !important;
}
.Admin-BG-Blue{
    background-color: #00395A;
}
.Admin-Div-Book-Buttons{
    float: right;
    margin-top: -38px;
    margin-left: 0px !important;
}
.Admin-Grid-Column-40{
    width: 40%;
}
.Admin-rtl{
    direction: rtl;
}
.Admin-Large-Font{
    font-size: 1.8em;
}
.Admin-Medium-Font{
    font-size: 1.5em;
}
.Admin-Red-Bold{
    font-weight: bold;
    color: red;
}
.h2-margin{
    margin-top: 0px !important;
    margin-bottom: 7px !important;
}
.Div-Matter-Dropdown{
    min-width: 600px;
}
.button-equilaw{
    z-index: -3 !important;
}
.div-postcode-wrapper{
    max-width: 100%;
}
.Admin-div-postcode-outer{
    width: 100%;
}
.Admin-div-postcode-inner{
    width: 100%;
    display: flex;
}
.Admin-ExceptionDateLabel{
    margin-top: -25px !important;
    padding-bottom: 10px;
}
.Admin-Div-CreateException{
    margin-top: -50px !important
}
.Admin-Div-existing-exceptions{
    overflow-y: scroll;
    max-height: 210px;
}
.label-postcode-margin{
    margin-bottom: 5px !important;
    margin-right: 5px !important;
}
.Admin-Hidden{
    display: none !important;
}
.Admin-EditUser-Remove-Email{
    cursor: pointer;
    margin-right: 0px;
}
.Admin-Modal-CancelApptReason{
    min-width: 400px !important;
    
}
.Div-Matter-Dropdown-Inner{
    margin-left: auto;
    margin-right: auto;
}
