.Login-Style{
    /* color: #F4911E !important; */
    color: #00395A !important;
}
@media (min-width: 768px) {    
    .Login-Segment-Wrapper{    
        width: 40% !important;
        margin-left: auto;
        margin-right: auto;
    }
}
@media (max-width: 768px) {    
    .Login-Segment-Wrapper{    
        width: 95% !important;
        margin-left: auto;
        margin-right: auto;
    }
}
.Login-Header-Segment{
    /* background-color: #F4911E !important; */
    /* background-color: #00395A !important; */
    background-color: var(--EquiMainColour) !important;
}
.Login-Header{
    color: var(--EquiTextColour) !important;
}
.Login-Segment-Input{
    width: 250px;
}
.Login-Remember-Label{
    vertical-align: top;
    margin-left: 0px;
}
.Login-Remember-Checkbox{
    margin-top: 0px;
}
.Login-Forgotten-Link{
    float: left;
    cursor: pointer;
}
.Login-Modal-Forgot-Username{
    width: 350px;
}
.Login-Button-Submit{
    background-color: var(--EquiMainColour) !important;
    color: var(--EquiTextColour) !important;
}
.Login-anchor-pointer{
    cursor: pointer !important;
}